<script setup>
import { RouterView } from 'vue-router';
import { onMounted } from 'vue';
import { instalarHeaderFooter } from 'https://arpen-header-footer-homolog.codebit.biz/header-footer.min.js';
import { useAuthStore } from '@/stores/auth';
import helpers from '@/helpers';

const authStore = useAuthStore();

onMounted(async () => {

  instalarHeaderFooter({
    usuario: {
      nome: authStore?.user?.name,
      cpf: authStore?.user?.cpf ? helpers.formatarCPF(authStore.user.cpf) : null,
      //foto: authStore?.user?.foto_de_perfil_url ? authStore?.user?.foto_de_perfil_url : null,
      foto: "",
    },
    urlLogin: authStore?.urlLogin(),
    urlLogout: authStore?.urlLogout(),
    token: authStore?.getToken(),
  });

});
</script>

<template>
  <div id="appContainer">
    <div id="header"></div> 
    <RouterView />
    <div id="footer"></div>
  </div>
</template>

<style scoped>
#main {
  min-height: 84vh;
} 
</style>

<style scoped lang="scss">
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';
@import 'https://arpen-header-footer-homolog.codebit.biz/header-footer.css';
#appContainer {
  overflow: v-bind(bodyOverflow);
  height: 100vh;
}
#content {
  background-color: white;
  @include media-breakpoint-up(sm) {
    margin-left: 75px;
  }
}
</style>